import extend from '../../../../extend';
export default function Noder() {}
extend(Noder.prototype, {
	computeNodes: function (segStrings) {},
	getNodedSubstrings: function () {},
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return Noder;
	}
});
