import extend from '../../../../../extend';
export default function ItemDistance() {}
extend(ItemDistance.prototype, {
	distance: function (item1, item2) {},
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return ItemDistance;
	}
});
