import extend from '../../../../extend';
export default function Puntal() {}
extend(Puntal.prototype, {
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return Puntal;
	}
});
