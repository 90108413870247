import extend from '../../../../extend';
export default function Lineal() {}
extend(Lineal.prototype, {
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return Lineal;
	}
});
