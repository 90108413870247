import extend from '../../../../extend';
export default function SegmentIntersector() {}
extend(SegmentIntersector.prototype, {
	processIntersections: function (e0, segIndex0, e1, segIndex1) {},
	isDone: function () {},
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return SegmentIntersector;
	}
});
