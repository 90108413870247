import extend from '../../../../../extend';
export default function QuadEdgeLocator() {}
extend(QuadEdgeLocator.prototype, {
	locate: function (v) {},
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return QuadEdgeLocator;
	}
});
