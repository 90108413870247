import extend from '../../../../../extend';
export default function EdgeSetIntersector() {}
extend(EdgeSetIntersector.prototype, {
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return EdgeSetIntersector;
	}
});
