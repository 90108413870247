import extend from '../../../../extend';
export default function ConstraintSplitPointFinder() {}
extend(ConstraintSplitPointFinder.prototype, {
	findSplitPoint: function (seg, encroachPt) {},
	interfaces_: function () {
		return [];
	},
	getClass: function () {
		return ConstraintSplitPointFinder;
	}
});
